import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from 'redux-thunk';
import {
    videosReducer,
    tokenReducer,
    guidesReducer,
    languageReducer,
    profileReducer,
    bannerReducer,
    countriesReducer,
    alertReducer,
    notesReducer,
    darkThemeReducer
} from '../reducers';

const reducer = combineReducers({
    user: tokenReducer,
    profile: profileReducer,
    videos: videosReducer,
    guides: guidesReducer,
    language: languageReducer,
    banners: bannerReducer,
    countries: countriesReducer,
    alert: alertReducer,
    notes: notesReducer,
    darkTheme: darkThemeReducer
})

const store = createStore(reducer, applyMiddleware(thunk))

export default store;