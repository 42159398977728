import { getProfile, getYears } from '../../services';

export const initProfile = (token) => {
    return async (dispatch) => {
        const profile = await getProfile(token)
        const years = await getYears(token.email)
        dispatch({
            type: 'SET_PROFILE',
            payload: {...profile, years: years}
        })
    }
}