import { Link } from "react-router-dom";
import { Banner, VideosList } from "../Components";
import { withNamespaces } from 'react-i18next';
import { useSelector } from "react-redux";

const Home = ({ t }) => {
    const darkTheme = useSelector(state => state.darkTheme)
    return (
        <>
            <Banner />
            <br/>
            <div className="mt-4 ms-3">
                <Link className={`${darkTheme ? 'text-white' : 'text-secondary'} FontSecondary text-uppercase`} to='/videos/sessions'><h1>{t("sessions")}</h1></Link>
                <br/>
                <VideosList />
            </div>
            <br/>
            <div className="mt-4 ms-3">
                <Link className={`${darkTheme ? 'text-white' : 'text-secondary'} FontSecondary text-uppercase`} to='/videos/grander_vision'><h1>{t("grander-vision")}</h1></Link>
                <br/>
                <VideosList category='grander_vision' />
            </div>
            <br/>
            <div className="mt-4 ms-3">
                <Link className={`${darkTheme ? 'text-white' : 'text-secondary'} FontSecondary text-uppercase`} to='/videos/bonus'><h1>{t("bonus-resources")}</h1></Link>
                <br/>
                <VideosList category="bonus" />
            </div>
            <br/>
            
        </>
    )
}

export default withNamespaces()(Home);