import axios from 'axios';
import { config } from './config'

const languagesURL = config.server + '/v0/user/GET/lang/'
const preferedLanguageURL = config.server + '/v0/user/PUT/profileLang/'

export const getLanguages = async () => {
    try {
        const {data} = await axios.get(languagesURL)
        return data
    } catch {
        return []
    }
}

export const setPreferedLanguage = async (token, preferred_language) => {
    try {
        await axios.patch(
            preferedLanguageURL + token.id + '/',
            { preferred_language },
            {headers: {
                'Authorization': `Bearer ${token.access}`
            }}
        )
        return true
    } catch { 
        return false
    }
}