import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { withNamespaces } from 'react-i18next';
import $ from 'jquery';

const Loader = ({ t }) => {

    const quotes1 = [
        t("loader-legend-1"),
        t("loader-legend-2"),
        // t("loader-legend-3"),
        // t("loader-legend-4"),
    ]
    const quotes2 = [
        t("loader-legend-3"),
        t("loader-legend-4"),
        // t("loader-legend-7"),
        // t("loader-legend-8"),
    ]
    const token = useSelector(state => state.user)
    const videos = useSelector(state => state.videos)
    const darkTheme = useSelector(state => state.darkTheme)
    var quote1 = quotes1[Math.floor(Math.random() * quotes1.length)];
    var quote2 = quotes2[Math.floor(Math.random() * quotes2.length)];
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(false)
    }, [])

    useEffect(() => {
        if (token.access && token.codes.length > 0 && videos.length === 0) setLoading(true)
    }, [token])

    useEffect(() => {
        if (videos.length > 0) setLoading(false)
    }, [videos])

    useEffect(() => {
            $(function(){
                $('#quotes span:gt(0)').hide();
                setInterval(function(){
                  $('#quotes :first-child').fadeOut(2000)
                     .next('span').fadeIn(2000).delay(5000)
                     .end().delay(5000).appendTo('#quotes');}, 
                  3000);
            });
    })

    return (
        <>
            {loading && 
            <div style={{zIndex: 1300}}>
                <div className={`loader d-flex justify-content-center align-items-center vh-100 vw-100 ${darkTheme ? 'bg-primary' : 'bg-white'} position-fixed`} style={{zIndex: 1300}}>
                    <div id="quotes" className="text-center vw-100">
                        <span className={`FontBold text-center ${darkTheme ? 'text-white quote' : 'quote-light text-blue-gls'}`}>
                            {quote1}
                        </span>
                        <span className={`FontBold  text-center ${darkTheme ? 'text-white quote' : 'quote-light text-blue-gls'}`}>
                            {quote2}
                        </span>
                    </div>
                    <div className={`spinner-border ${darkTheme ? 'text-white' : 'text-blue-gls'} mt-5`} role="status" style={{width: '5rem', height: '5rem'}}>
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
            }
        </>
    )
}

export default withNamespaces()(Loader);