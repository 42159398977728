import { useVideos } from "../../hooks";
import { VideoCard } from "../VideoCard";
import PropTypes from 'prop-types';

export const VideosList = (props) => {
    const { category } = props
    const { sessions, granderVision, bonus } = useVideos()

    const sessionsSorted = sessions.sort((a, b) => parseInt(b.pk) - parseInt(a.pk));
    const granderVisionSorted = granderVision.sort((a, b) => parseInt(b.year) - parseInt(a.year));
    const bonusSorted = bonus.sort((a, b) => parseInt(b.year) - parseInt(a.year));

    const sessionsList = sessionsSorted.map((video) =>
        <VideoCard key={video.id} video={video} />
    )
    const granderVisionList = granderVisionSorted.map((video) =>
        <VideoCard key={video.id} video={video} />
    )
    const bonusList = bonusSorted.map((video) =>
        <VideoCard key={video.id} video={video} />
    )

    const selectedCategory = () => {
        switch (category) {
            case 'grander_vision':
                return granderVisionList;
            case 'bonus':
                return bonusList;
            default:
                return sessionsList;
        }
    }

    return (
        <div className="row ms-3 me-3">
            {selectedCategory()}
        </div>
    )
}

VideosList.propTypes = {
    category: PropTypes.oneOf(["sessions", "grander_vision", "bonus"]),
}

VideosList.defaultProps = {
    category: 'sessions'
};
