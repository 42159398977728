import { useParams } from "react-router-dom";
import { VideosList } from '../Components';
import { withNamespaces } from 'react-i18next';
import { useSelector } from "react-redux";

const VideosCategory = ({ t }) => {
    const params = useParams()
    const darkTheme = useSelector(state => state.darkTheme)

    const selectedCategory = () => {
        switch (params.category) {
            case 'sessions':
                return t('cursos');
            case 'grander_vision':
                return t('conferencias');
            default:
                return t('bonus');
        }
    }
 
    return (
        <div className="mt-1">
            <br/>
            <h1 className={`${darkTheme ? 'text-white' : 'text-secondary'} ms-3 mt-3 FontSecondary text-uppercase`}>{selectedCategory()}</h1>
            <br/>
            <VideosList category={params.category}/>
            <br/>
            <br/>
        </div>
    )
}

export default withNamespaces()(VideosCategory);