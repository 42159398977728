import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../Redux/actions';
import { Button } from './Button';

const Alert = () => {
    const dispatch = useDispatch()
    const alert = useSelector(state => state.alert)
    const message = typeof alert === 'string' ?  alert : alert?.message
    
    const closeAlert = async () => {
        dispatch(setAlert(null))
    }

    return (
        <>
            {alert && <div className="modal d-block" tabIndex="-1">
                <div className="modal-dialog">
                    <div className={alert?.type === 'error' ? 'bg-danger modal-content p-3' : "bg-info modal-content p-3"}>
                        <p className='text-center text-white text-uppercase'>{message}</p>
                        <Button type="button" className='ms-auto' onClick={() => closeAlert()}>OK</Button>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default withNamespaces()(Alert);