import play from '../../assets/img/play.png'
import "./VideoCard.css"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export const VideoCard = ({ video }) => {
    const language = useSelector(state => state.language.selected.key)
    const defaultTitle = video.titles.filter(title => title.lang_code === 'eng')
    const defaultCardImage = video.posters.filter(poster => poster.lang_code === 'eng')
    const title = video.titles.filter(title => title.lang_code === language)
    const cardImage = video.posters.filter(poster => poster.lang_code === language)

    const darkTheme = useSelector(state => state.darkTheme)

    return (
        <div className="col-12 col-sm-6 col-md-4 col-xl-3 p-2">
            <div className={`card border-0 ${darkTheme ? 'card-shadow' : 'card-shadow-light'} `}>
                <Link className="playContainer bg-dark" to={'/player/' + video.id + '/' + video['speaker-slug']}>
                    <img
                        src={ cardImage[0]?.poster
                            ? `https://api.conectafy.com${cardImage[0]?.poster}`
                            : `https://api.conectafy.com${defaultCardImage[0].poster}`}
                        className="card-img-top"
                        alt="cardImage"
                    />
                    <img src={play} className="play col-3" />
                </Link>
                <div className= "card-body d-flex align-items-center px-2 text-white bg-secondary font-italic"
                >
                    <span className="card-text FontBold ps-1 pe-3 py-2 m-0">
                        { title[0]?.title || defaultTitle[0].title }
                    </span>
                    <span className="FontSecondary position-absolute" style={{right: '12px', bottom: '3px', fontSize: '13px'}}>
                        { video.year }
                    </span>
                </div>
            </div>
        </div>
    )
}

VideoCard.propTypes = {
    video: PropTypes.any,
}