export { default as Landing } from './Landing';
export { default as Login } from './Login';
export { default as Home } from './Home';
export { default as NotFound } from './NotFound';
export { default as VideosCategory } from './VideosCategory';
export { default as DiscussionGuides } from './DiscussionGuides';
export * from './Notes';
export { default as Profile } from './Profile';
export { default as Player } from './Player';
export { default as Section1 } from './Section1';
export { default as Copyright } from './Copyright';
export { default as TermsOfUse } from './TermsOfUse';
export { default as ContactUs } from './ContactUs';
export { default as Feedback } from './Feedback';