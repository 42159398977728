import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import {
  Landing,
  Section1,
  Copyright,
  TermsOfUse,
  ContactUs,
  Feedback,
  Login,
  Home,
  VideosCategory,
  Notes,
  DiscussionGuides,
  Profile,
  NotFound,
  Player
} from './Pages';
import { NavBar, Footer, Loader, Alert, LanguagesModal, ScrollToTop } from './Components';
import { useDispatch,  useSelector } from 'react-redux';
import { useEffect } from 'react';
import { initGuides, initProfile, initLanguages, initBanners, initCountries, initNotes, login } from './Redux/actions';

function App() {
  const user = useSelector(state => state.user)
  const darkTheme = useSelector(state => state.darkTheme)
  const dispatch = useDispatch()
  const token = window.localStorage.getItem('GLSNToken')

  useEffect(() => {
    dispatch(initLanguages())
    dispatch(initCountries())
    dispatch(initBanners())
    
    if(token) {
      document.body?.classList.add(darkTheme ? 'bg-dark': 'bg-white');
      const user = JSON.parse(token)
      dispatch(login(user))
      dispatch(initGuides())
      dispatch(initProfile(user))
      dispatch(initNotes(user))
    }
  }, [dispatch, token])

  useEffect(() => {
    if (token) {
      document.body?.classList.remove(darkTheme ? 'bg-white': 'bg-dark');
      document.body?.classList.add(darkTheme ? 'bg-dark': 'bg-white');
    }
  }, [darkTheme])

  return (
    <>
      <BrowserRouter>
        <Loader />
        <ScrollToTop />
        <LanguagesModal />
        <NavBar />
        <Alert />
        <Routes>
          <Route path='/' element={
            token ? <Navigate to='home'/> : <Landing />
          } />
          <Route path='/section1' element={
            token ? <Navigate to='/home'/> : <Section1 />
          } />
          <Route path='/copyright' element={
            <Copyright />
          } />
          <Route path='/terms-of-use' element={
            <TermsOfUse />
          } />
          <Route path='/contact-us' element={
            <ContactUs />
          } />
          < Route path='/feedback' element={
             <Feedback />
          } />
          <Route path='/login/:method' element={
            user.access ? <Navigate to='/home'/> : <Login />
          } />
          <Route path='/login/:method/:code' element={
            user.access ? <Navigate to='/home'/> : <Login />
          } />
          <Route path='/qr/login/' element={
            user.access ? <Navigate to='/home'/> : <Login />
          } />
          <Route path='/home' element={
            token ? <Home /> : <Navigate to='/login/code'/>
          } />
          <Route path='/videos/:category' element={
            token ? <VideosCategory /> : <Navigate to='/login/code'/>
          } />
          <Route path='/player/:videoId/:speakerSlug' element={
            token ? <Player /> : <Navigate to='/login/code'/>
          } />
          <Route path='/notes' element={
            token ? <Notes /> : <Navigate to='/login/code'/>
          } />
          <Route path='/discussion_guides' element={
            token ? <DiscussionGuides /> : <Navigate to='/login/code'/>
          } />
          <Route path='/profile' element={
            token ? <Profile />: <Navigate to='/login/code'/>
          } />
          <Route path='*' element={<NotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
