import axios from 'axios';
import { config } from './config'

const guidesURL = config.server + '/v0/media/GET/DiscussionGuide/'

export const getGuides = async () => {
    try {
        const {data} = await axios.get(guidesURL)
        return data
    } catch {
        return null
    }
}