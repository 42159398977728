import axios from 'axios';
import { config } from './config';

const videosURL = config.server +  '/v0/media/GET/Video_resume/'

export const getVideos = async (token) => {
    try {
        const {data} = await axios.get(videosURL + token.id + '/')
        return data
    } catch {
        return []
    }   
}