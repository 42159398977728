import { CgClose } from 'react-icons/cg'
import { withNamespaces } from 'react-i18next';
import { translator } from '../../i18n';
import { setLanguage, setToken } from '../../Redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import './languagesModal.css'
import { refreshToken, setPreferedLanguage } from '../../services';
import { useEffect } from 'react';

const LanguagesModal = ({ t }) => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const profile = useSelector(state => state.profile)
    const languagesState = useSelector((state) => state.language.languages)
    const languageSelected = useSelector((state) => state.language.selected)
    const darkTheme = useSelector(state => state.darkTheme)
    const languages = languagesState.sort(SortLanguages)

    useEffect(() => {
        if (user.access && profile?.preferred_language) {
            const preferred = languages.filter(language => language.id === profile.preferred_language)
            dispatch(setLanguage({id: preferred[0].id, name: preferred[0].lang, key: preferred[0].iso639_2}))
            translator.changeLanguage(preferred[0].iso639_2)
        }
    }, [profile])

    function SortLanguages(first, second){
        if (first.lang < second.lang) {return -1;}
        if (first.LastName > second.LastName) {return 1;}
        return 0;
    }

    const changeLanguage = async (id, language, key) => {
        translator.changeLanguage(key)
        dispatch(setLanguage({id, name: language, key}))
        if (user?.access) {
            const newToken = await refreshToken(user)
            setPreferedLanguage(newToken, id)
            dispatch(setToken(newToken))
        }
    }

    return (
        <div className="modal fade" id="languageModal" tabIndex="-1">
            <div className="modal-dialog modal-xl">
                <div className={`modal-content text-dark p-3 ${darkTheme ? 'bg-secondary' : 'bg-info'}`}>
                    <div className="modal-body">
                        <div className='d-flex justify-content-between mb-2'>
                            <h4 className={`modal-title ${!darkTheme && 'text-white'}`}>{t("select-language")}</h4>
                            <button type="button" className="btn btn-transparent" data-bs-dismiss="modal" aria-label="Close">
                                <CgClose className='text-white fw-bolder' style={{ fontSize: '28px'}} />
                            </button>
                        </div>
                        <div className='row flex-column language-module-content p-1'>
                            {languages.map((language) => 
                                <button key={language.slug}
                                className={`btn btn-transparent col-11 col-sm-6 col-lg-4 col-xl-3 text-dark text-start ${language.iso639_2 === languageSelected.key ? 'FontBold' : ''}`}
                                data-bs-toggle="modal"
                                data-bs-target="#languageModal"
                                onClick={() => changeLanguage(language.id, language.lang, language.iso639_2)}>
                                    <h5 className={`${!darkTheme && 'text-white'}`}>{language.lang}</h5>
                                </button>    
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withNamespaces()(LanguagesModal);