import axios from 'axios';
import { config } from './config'

const bannerURL = config.server + '/v0/media/GET/BannerElement/'

export const getBanners = async () => {
    try {
        const {data} = await axios.get(bannerURL)
        return data
    } catch {
        return null
    }
}