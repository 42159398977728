import { withNamespaces } from 'react-i18next';
import { Button } from "../Components";
import { FiFileText } from "react-icons/fi"
import { BsDownload } from "react-icons/bs"
import cardBar from "../assets/img/barra-profile.png"
import { useDispatch, useSelector } from 'react-redux';
import { useRef, useState } from 'react';
import { createNote, getDownloadNote, refreshToken, updateNote } from "../services";
import { initNotes, setAlert, setToken } from '../Redux/actions';
import { Editor } from '@tinymce/tinymce-react';
import parseHTML from 'react-html-parser'

const Notes = ({ t }) => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const notes = useSelector(state => state.notes)
    const [selectedNote, setSelectedNote] = useState()
    const [noteTitle, setNoteTitle] = useState('')
    const noteEditorRef = useRef()
    
    const selectNote = (note) => {
        setSelectedNote(note)
        setNoteTitle(note.title)
    }

    const saveNote = async (event) => {
        event.preventDefault()
        if(selectedNote) {
            const newToken = await refreshToken(user)
            await updateNote(newToken, selectedNote.id, noteTitle, noteEditorRef.current.getContent())
            dispatch(setToken(newToken))
            dispatch(setAlert(t('success-save-note')))
        } else {
            const newToken = await refreshToken(user)
            const newNote = await createNote(newToken, noteTitle, noteEditorRef.current.getContent())
            dispatch(setToken(newToken))
            setSelectedNote(newNote)
            dispatch(setAlert(t('success-create-note')))
        }
        dispatch(initNotes(user))
    }

    const handleDownload = async (id) => {
        const notePDF = await getDownloadNote(id)
        window.open(notePDF['PDF download URL'], "_blank");
    }

    return (
        <>
            <div className="card overflow-visible p-5 ms-5 me-5 mb-5">
                <div className="d-flex justify-content-between">
                    <h2 className="FontBold">{selectedNote ? t("update-note") : t("create-note")}</h2>
                    <img className="d-none d-md-block me-5" style={{ maxHeight: '30px' }} src={cardBar} alt='bar-profile'/>
                </div>
                <form className="col-12 mt-3 d-flex flex-column align-items-center" onSubmit={saveNote}>
                    <input className="form-control mb-4" placeholder={t('title')} value={noteTitle} onChange={(e) => setNoteTitle(e.target.value)}/>
                    <Editor
                        onInit={(event, editor) => noteEditorRef.current = editor}
                        apiKey='h4ova2hn2szuxtz6c3bf0qqa3nkdqtbf0f60lmbi1fgadosi'
                        initialValue={selectNote ? selectedNote?.text : ''}
                        init={{
                            width: '100%',
                            toolbar: 'undo redo | formatselect | ' +
                            'bold italic underline | alignleft aligncenter ' +
                            'alignright alignjustify | bullist numlist outdent indent | '
                        }}
                    />
                    <Button className="mt-3" secondary>{t("save")}</Button>
                </form>
            </div>
            <div className="card overflow-visible p-5 ms-5 me-5">
                <div className="d-flex justify-content-between mb-4">
                    <h2 className="FontBold">{t("notes")}</h2>
                    <img className="d-none d-md-block me-5" style={{ maxHeight: '30px' }} src={cardBar} alt='bar-profile'/>
                </div>
                <div className="row">
                    {notes.map(note =>
                        <div key={note.title} className="col-12 col-md-6 p-3 mb-2 " onClick={() => selectNote(note)}>
                            <div className="d-flex col-12 overflow-hidden border-bottom">
                                <div className='me-3'><FiFileText size={30}/></div>
                                <div style={{width: '90%'}}>
                                    <h5 className="fw-bolder mb-2">{note.title}</h5>
                                    <p className="w-100 text-truncate" style={{maxHeight: '24px'}}>{parseHTML(note.text)}</p>
                                    <p className="fw-bold text-info" onClick={() => handleDownload(note.id)} style={{cursor: 'pointer'}}>
                                        <BsDownload className="me-3" size={25}/>{t("download")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default withNamespaces()(Notes);