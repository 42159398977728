import { useSelector } from "react-redux"
import { GuideList } from "../Components"
import { withNamespaces } from 'react-i18next';

const DiscussionGuides = ({ t }) => {
    const guides22 = useSelector(state => state.guides.filter(guide => guide.year === 1))
    const guides20 = useSelector(state => state.guides.filter(guide => guide.year === 3))
    const guides21 = useSelector(state => state.guides.filter(guide => guide.year === 4))
    const guides19 = useSelector(state => state.guides.filter(guide => guide.year === 6))
    const darkTheme = useSelector(state => state.darkTheme)

    return (
        <div className="row mt-4 p-3 mb-1 justify-content-start align-items-center w-100">
            <h1 className={`${darkTheme ? 'text-white' : 'text-secondary'} ms-1 mb-2 text-uppercase FontSecondary`}>{t("recursos")}</h1>
            
            {guides22.length > 0 &&
                <>
                    <br/>
                    <h2 className={`${darkTheme && 'text-white'} ms-3 mt-3 mb-4 FontSecondary`}>2022</h2>
                    <br/>
                    <GuideList guides={guides22} />
                </>
            }

            {guides21.length > 0 &&
                <>
                    <br/>
                    <h2 className={`${darkTheme && 'text-white'} ms-3 mt-3 mb-4 FontSecondary`}>2021</h2>
                    <br/>
                    <GuideList guides={guides21} />
                </>
            }
            
            {guides20.length > 0 &&
                <>
                    <br/>
                    <h2 className={`${darkTheme && 'text-white'} ms-3 mt-5 mb-4 FontSecondary`}>2020</h2>
                    <br/>
                    <GuideList guides={guides20} />
                </>
            }
            
            {guides19.length > 0 &&
                <>
                    <br/>
                    <h2 className={`${darkTheme && 'text-white'} ms-3 mt-5 mb-4 FontSecondary`}>2019</h2>
                    <br/>
                    <GuideList guides={guides19} />
                </>
            }
        </div>
    )
}

export default withNamespaces()(DiscussionGuides)