import { withNamespaces } from 'react-i18next';
import { useEffect } from "react";
import titleImg1 from '../assets/img/titleSection1.png';
import waves from '../assets/img/ondas-xl.png'
import sonar from '../assets/img/Group1.png'
import appsExample from '../assets/img/iPhone-iPad-Mockup2.png';
import { Link } from "react-router-dom";
import { RiHome7Fill } from "react-icons/ri";
import { RiArrowLeftCircleFill } from "react-icons/ri";
import { RiArrowRightCircleFill } from "react-icons/ri";
import titleImg2 from '../assets/img/titleSection3.png';
import appstore from '../assets/img/appstore.png';
import playstore from '../assets/img/googleplay.png';
import codeActivation from '../assets/img/qr-per-1.png';
import titleImg3 from '../assets/img/titleSection3.png';
import newContent from '../assets/img/mockup-tvcompu-1.png';
import barcode from '../assets/img/Group2.png';
import { useSelector } from 'react-redux';

const Section1 = ({ t }) => {
    const darkTheme = useSelector(state => state.darkTheme)

    useEffect(() => {
        document.body?.classList.remove(darkTheme ? 'bg-login' : 'bg-login-light');
        document.body?.classList.remove(darkTheme ? 'bg-landing' : 'bg-landing-light');
        document.body?.classList.add(darkTheme ? 'bg-dark' : 'bg-white');
    });

    useEffect(() => {
        document.body?.classList.remove(darkTheme ? 'bg-white': 'bg-dark');
        document.body?.classList.add(darkTheme ? 'bg-dark': 'bg-white');
    }, [darkTheme])

    return (
        <div>
        {/* SECTION 1 */}
        <div className='container-fluid'>
            <div className='btn-group d-none d-lg-block' style={{ position: 'absolute', bottom: '10%', right: '15px' }} role="group" aria-label="Basic example">
                    <button className='btn btn-secondary'>
                        <Link to="/"> <RiHome7Fill size={25} /></Link>
                    </button>
                    <button className='btn btn-secondary'>
                        <Link to="/section1"> <RiArrowLeftCircleFill size={25} /> </Link>
                    </button>
                    <button className='btn btn-secondary'>
                        <Link to="/copyright"> <RiArrowRightCircleFill size={25} /> </Link>
                    </button>
            </div>
            <div className='mt-5'>
                <div className='row align-items-center py-lg-4'>
                    <img className="section1-sonar" src={ sonar } alt="Sonar GLSNow"/>
                    <div className='col-lg-7 ms-auto me-auto'>
                        <center>
                            <img className="img-fluid" src={ titleImg1 } style={{maxHeight: '100px'}} alt="Leadership to go, anywhere GLSNow"/>       
                        </center>
                    </div>
                </div>
                <div className='row'>
                    <img className="section1-waves" src={ waves } alt="Waves GLSNow"/>
                    <div className='col-lg-7 ps-1'>
                        <img className="img-fluid" src={ appsExample } style={{minHeight: '150px'}} alt="Activate GLSNow"/>
                    </div>
                    <div className='col-lg-5 p-5'>
                        <br/>
                        <br/>
                        <h4 className='text-white text-end text-landing-mobile' style={{ paddingLeft: '20%' }}>
                            {t("text-section1-1")}
                        </h4>
                        <br/>
                        <h4 className='text-white text-end text-landing-mobile'>
                            {t("text-section1-2")}
                        </h4>
                        <h4 className='text-white text-end text-landing-mobile'>
                            {t("text-section1-3")}
                        </h4>
                        <br/>
                        <center>
                            <img className="img-fluid p-2" src={ appsExample } style={{maxHeight: '80px', display: 'none' }} alt="Apple AppStore GLSNow"/>
                        </center>
                        </div>
                    
                </div>
            </div>
        </div>
        {/* SECTION 2 */}
        <div className='container-fluid' style={{ height: '100vh' }}>
            <div className='btn-group d-none d-lg-block' style={{ position: 'absolute', bottom: '10%', right: '15px' }} role="group" aria-label="Basic example">
                    <button type="button" className='btn btn-secondary'>
                        <Link to="/"> <RiHome7Fill size={25} /></Link>
                    </button>
                    <button type="button" className='btn btn-secondary'>
                        <Link to="/section1"> <RiArrowLeftCircleFill size={25} /> </Link>
                    </button>
                    <button type="button" className='btn btn-secondary'>
                        <Link to="/terms-of-use"> <RiArrowRightCircleFill size={25} /> </Link>
                    </button>
            </div>
            <div className='mt-5'>
                <div className='row align-items-center py-2'>
                    <div className='col-lg-7 ms-auto me-auto'>
                        <center>
                            <img className="img-fluid" src={ titleImg2 } style={{maxHeight: '100px'}} alt="Simply Scan and Activate GLSNow"/>       
                        </center>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-5 p-5'>
                        <br/>
                        <br/>
                        <h4 className='text-white text-center'>
                            {t("text-section2-1")}
                        </h4>
                        <img className="section2-sonar" src={ sonar } alt="Sonar GLSNow"/>
                        <br/>
                        <center>
                            <img className="img-fluid p-2" src={ appstore } style={{maxHeight: '80px'}} alt="Apple AppStore GLSNow"/>
                            <img className="img-fluid p-2" src={ playstore } style={{maxHeight: '80px'}} alt="Google PlayStore GLSNow"/>       
                        </center>
                        </div>
                    <div className='col-lg-7 pt-2 me-0 pe-0 ps-5 mb-5'>
                        <br/>
                        <img className="img-fluid pt-2 ps-2" src={ codeActivation } style={{minHeight: '150px'}} alt="Activate GLSNow"/>
                    </div>
                </div>
            </div>
        </div>
        {/* SECTION 3 */}
        <div className='container-fluid' style={{ height: '90vh' }}>
            <div className='btn-groupd-none d-lg-block' style={{ position: 'absolute', bottom: '10%', right: '15px' }} role="group" aria-label="Basic example">
                    <button type="button" className='btn btn-secondary'>
                        <Link to="/"> <RiHome7Fill size={25} /></Link>
                    </button>
                    <button type="button" className='btn btn-secondary'>
                        <Link to="/copyright"> <RiArrowLeftCircleFill size={25} /> </Link>
                    </button>
                    <button type="button" className='btn btn-secondary'>
                        <Link to="/contact-us"> <RiArrowRightCircleFill size={25} /> </Link>
                    </button>
            </div>
            <div className='mt-5'>
                <div className='row align-items-center py-3'>
                    <div className='col-lg-7 ms-auto me-auto'>
                        <center>
                            <img className="img-fluid" src={ titleImg3 } style={{maxHeight: '100px'}} alt="Grow your leadership GLSNow"/>       
                        </center>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-4 pt-5 ps-5'>
                        <br/>
                        <h4 className='text-white text-start'>
                            {t("text-section3-1")}
                        </h4>
                        <br/>
                        <h4 className='text-white text-start'>
                            {t("text-section3-2")}
                        </h4>
                        <img className="section3-barcode" src={ barcode } alt="Barcode GLSNow"/>
                        </div>
                    <div className='col-lg-8 p-3 pt-0'>
                        <img className="img-fluid" src={ newContent } style={{minHeight: '150px', marginTop: '-50px'}} alt="Apps GLSNow"/>
                    </div>
                </div>
            </div>
        </div>
        {/* SECTION 4 */}
        < >
        <div className='container-fluid' style={{  }}>
            <br/>
            <br/>
            <div className='btn-group d-none d-lg-block' style={{ position: 'absolute', bottom: '10%', right: '15px' }} role="group" aria-label="Basic example">
                    <button type="button" className='btn btn-secondary'>
                        <Link to="/"> <RiHome7Fill size={25} /></Link>
                    </button>
                    <button type="button" className='btn btn-secondary'>
                        <Link to="/terms-of-use"> <RiArrowLeftCircleFill size={25} /> </Link>
                    </button>
                    <button type="button" className='btn btn-secondary'>
                        <Link to="/contact-us"> <RiArrowRightCircleFill size={25} /> </Link>
                    </button>
            </div>
            <div className='row text-center py-lg-5 faq pt-5'>
                <h1 className='text-white FontBold'> FAQ´s </h1>
                <br/>
                <div className='row mb-5 px-5'>
                    <div className='col-lg-4'>
                        <h2 className='text-white mt-5'> 
                            {t("text-section4-1")} 
                        </h2>
                        <br/>
                        <br/>
                        <h6 className='text-white px-2'> 
                            {t("text-section4-2")} 
                            <br/>
                            <br/>
                            {t("text-section4-3")}
                        </h6>
                    </div>
                    <div className='col-lg-4'>
                        <h2 className='text-white px-5 mt-5'>
                            {t("text-section4-4")} 
                        </h2>
                        <br/>
                        <h6 className='text-white px-2'>
                            {t("text-section4-5")} 
                            <br/>
                            <br/>
                            {t("text-section4-6")}
                        </h6>
                    </div>
                    <div className='col-lg-4'>
                        <h2 className='text-white px-5 mt-5'>
                            {t("text-section4-7")}
                        </h2>
                        <br/>
                        <h6 className='text-white px-2'>
                            {t("text-section4-8")}
                            <br/>
                            <br/>
                            {t("text-section4-9")}
                            <br/>
                            {t("text-section4-10")}
                            <br/>
                            {t("text-section4-11")}
                        </h6>
                    </div>
                </div>
            </div>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
        </>
        {/* ENDS */}
        </div>
        
    )
}

export default withNamespaces()(Section1);