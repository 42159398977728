import { FiArrowDown } from "react-icons/fi"
import { RiFileCopy2Fill } from "react-icons/ri"
import PropTypes from 'prop-types'
import { Button } from "."

const GuidesList = ({guides}) => {
    return guides?.map((guide) => 
    <div key={guide.language} className="col-lg-6 ps-3 pb-2">
        <div className="card mb-3 py-4 ps-1 pe-3 discussionGuideCard" style={{minHeight: '140px'}}>
            <h4 className="text-center">{guide.title}</h4>
                <div className="d-flex align-items-center pt-2">
                        <div className="ms-4 d-flex align-items-center">
                        <RiFileCopy2Fill size={45}/>
                    <h5 className="ms-3 mb-0">{guide.description}</h5>
                </div>
                <a target='_blank' rel='noreferrer' href={guide?.file_uri} className="ms-auto">
                    <Button secondary><FiArrowDown/>{guide.download_string}</Button>
                </a>
            </div>
        </div>
    </div>
    )
}

GuidesList.propTypes = {
    guides: PropTypes.arrayOf(PropTypes.PropTypes.shape({
        language: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
        file_uri: PropTypes.string,
        download_string: PropTypes.string
    })).isRequired
}

export default GuidesList;