import { useDispatch, useSelector } from "react-redux"
import { useState } from "react"
import avatar from "../assets/img/profile-pic.png"
import bg from "../assets/img/barra-profile.png"
import tooltipIcon from "../assets/img/icon-tooltip.png"
import { withNamespaces } from 'react-i18next';
import { Selector, Button } from "../Components";
import { addCode, getToken, refreshToken, setUserInfo, verifyCode, verifyCodeRegister } from "../services";
import { initProfile, login, setAlert, setToken } from "../Redux/actions";
import ReactTooltip from 'react-tooltip';

const Profile = ({ t }) => {
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const countries = useSelector(state => state.countries)
    const profile = useSelector(state => state.profile)
    const [email, setEmail] = useState(profile.email)
    const [firstname, setFirstname] = useState(profile.first_name)
    const [lastname, setLastname] = useState(profile.last_name)
    const [country, setCountry] = useState(profile.country)
    const [code, setCode] = useState('')
    const [birthdate, setBirthdate] = useState(profile.date_of_birth)
    const [gender, setGender] = useState(profile.gender === 's' ? '' : profile.gender)
    const genders = [{value: 'm', label: t('male')}, {value: 'f', label: t('female')}, {value: 'x', label: t('not-mentioned')}]

    const handleInfo = async () => {
        const newToken = await refreshToken(user)
        await setUserInfo({token: newToken, email, firstname, lastname, country, birthdate, gender})
        dispatch(setToken(newToken))
        dispatch(initProfile(newToken))
        dispatch(setAlert(t('success-update-info')))
    }

    const handleCode = async () => {
        if (await verifyCode(code)) {
            if (!(await verifyCodeRegister(code))) {
                const newToken = await refreshToken(user)
                await addCode(newToken, code)
                const updatedToken = await getToken({code})
                dispatch(login(updatedToken))
                dispatch(setAlert(t('success-add-code')))
            } else {
                dispatch(setAlert({type: 'error', message: t('invalid-code')}))
            }
        } else {
            dispatch(setAlert({type: 'error', message: t('invalid-code')}))
        }
    }

    return (
        <div style={{ marginTop: '10px' }}>
        <div className="card overflow-visible p-4 p-md-5 m-4 m-md-5">
            <div className="d-flex justify-content-between">
                <h2>{t("edit-profile")}</h2>
                <img className="d-none d-md-block me-5 col-4" src={bg} alt='bar-profile'/>
            </div>
            <div className="row">
                <div className="col-12 col-md-6 d-flex flex-column align-items-center justify-content-center">
                    <div className="d-flex align-items-center flex-md-column">
                        <img className="profile-avatar rounded-pill mb-2" alt="avatar" src={avatar} />
                        <Button className="ms-4 ms-md-0" style={{ display: 'none'}} secondary>{t("change-photo")}</Button>
                    </div>
                    <br/>
                    <form className="col-12 col-sm-11 col-md-8 mt-3 d-flex flex-column align-items-center">
                        <input className="form-control mb-4" placeholder={t("email")} value={email} onChange={(e) => setEmail(e.target.value)}/>
                        <input className="form-control mb-4" placeholder={t("first-name")} value={firstname} onChange={(e) => setFirstname(e.target.value)}/>
                        <input className="form-control mb-4" placeholder={t("last-name")} value={lastname} onChange={(e) => setLastname(e.target.value)}/>
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">{t('birthdate')}</span>
                            <input type="date" className="form-control"
                            value={birthdate} onChange={(e) => setBirthdate(e.target.value)}/>
                        </div>
                        <Selector placeholder={t('gender')} selected={gender} options={genders} onChange={(value) => setGender(value)} textColor='black'/>
                        <Selector placeholder={t('country')} selected={country} options={countries} onChange={(value) => setCountry(value)} textColor='black'/>
                        <Button type='button' secondary onClick={() => handleInfo()}>{t("save")}</Button>
                    </form>
                </div>
                <center className="col-12 col-md-6 d-flex flex-column justify-content-center">
                    <h4 className="mt-5 mt-md-0 mb-4 FontBold">{t("user-information")}</h4>
                    <h4 className="mb-4">{profile?.first_name} {profile?.last_name}</h4>
                    <b className="FontBold">{t("active-codes")}:</b>
                    <div className="mb-4">
                        {user && profile.years?.map((year) =>
                            <p className="mb-0" key={year}>{year}</p>)
                        }
                    </div>
                    <br/>
                    <h4 className="mb-3">
                        <span className="FontBold">{t("add-content-codes")}</span> 
                        <img className="ms-3" data-tip="hello world" src={tooltipIcon} alt='tooltip'/>
                        <ReactTooltip place="top" effect="float" className="w-25 p-3"
                        backgroundColor='#009CDE' borderColor='#009CDE'
                        >
                            <h6 className="FontBold">{t("add-content-codes-tooltip-title")}</h6>
                            <p>{t("add-content-codes-tooltip-text")}</p>
                        </ReactTooltip>
                    </h4>
                    <div className="col-12 col-sm-11 col-md-8 ms-auto me-auto">
                        <input className="form-control" placeholder={t('code')} value={code} onChange={(e) => setCode(e.target.value)} />
                        <Button secondary className="mt-4" onClick={() => handleCode()}>{t("apply-code")}</Button>
                    </div>
                </center>
            </div>
        </div>
        <br/>
        <br/>
        </div>
    )
}

export default withNamespaces()(Profile);