import PropTypes from 'prop-types'
import './Button.css'
import { useSelector } from 'react-redux';

const Selector = ({secondary, type, className, onClick, children, style}) => {

    const darkTheme = useSelector(state => state.darkTheme)

    return (
        <button
            type={type}
            className={secondary
                ? 'btn btn-outline-info border-1 rounded-pill text-uppercase fw-bold secondary-btn-text ' + className
                : `btn btn-light border-1 rounded-pill text-uppercase fw-bold primary-btn-text-light` + className}
            onClick={onClick}
            style={{minWidth: '100px', ...style}}
        >
            {children}
        </button>
    )
}

Selector.propTypes = {
    secondary: PropTypes.bool,
    type: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
    children: PropTypes.any
}

Selector.defaultProps = {
    secondary: false,
    type: 'submit',
    className: '',
    onClick: null,
    children: null
}

export default Selector;
