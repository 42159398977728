import axios from 'axios';
import { config } from './config';

const yearsURL = config.server + '/v0/media/GET/years_per_user/'
const addCodeURL = config.server + '/v0/user/POST/user_code/'
const setUserInfoURL = config.server + '/v0/user/PUT/profile/'
const getProfileURL = config.server + '/v0/user/GET/profile/'

export const getYears = async (email) => {
    try {
        const {data} = await axios.get(yearsURL + '?email=' + email)
        let years = []
        for (let year of data.years) {
            if(!years.includes(year.year)) {
                years.push(year.year)
            }
        }
        return years.sort()
    } catch(e) {
        return e
    }
}

export const addCode = async (token, code) => {
    try {
        await axios.post(addCodeURL, {id_user: token.id.toString(), code}, {
            headers: {
              'Authorization': `Bearer ${token.access}`
            },
        })
        return null
    } catch(e) {
        return e
    }
}

export const setUserInfo = async ({token, email, firstname, lastname, country, birthdate, gender}) => {
    try {
        await axios.put(
            setUserInfoURL + token.id + '/',
            {country, date_of_birth: birthdate, gender, email, first_name: firstname, last_name: lastname},
            {headers: {
                'Authorization': `Bearer ${token.access}`
            }}
        )
        return null
    } catch(e) {
        return e
    }
}

export const getProfile = async (token) => {
    try {
        const {data} = await axios.get(getProfileURL + token.id + '/')
        return data
    } catch(e) {
        return e
    }
}