import { Link, useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login, setAlert } from "../Redux/actions";
import { withNamespaces } from 'react-i18next';
import logoWhite from '../assets/img/logo-green.png'
import {
    addCode,
    createAccount,
    getToken,
    verifyCode,
    verifyCodeRegister,
    verifyEmail,
} from "../services";
import { Button, Selector } from "../Components";

const Login = ({ t }) => {
    const params = useParams()
    const countries = useSelector(state => state.countries)
    const darkTheme = useSelector(state => state.darkTheme)
    const language = useSelector(state => state.language.selected)
    const legacyCode = new URLSearchParams(useLocation().search).get('code');
    const [step, setStep] = useState(legacyCode ? 'email' : params.method)
    const [code, setCode] = useState(legacyCode || params.code || '')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [firstname, setFirstName] = useState('')
    const [lastname, setLastName] = useState('')
    const [phone, setPhone] = useState('')
    const [work, setWork] = useState('')
    const [city, setCity] = useState('')
    const [country, setCountry] = useState('')
    const [error, setError] = useState(null)
    const dispatch = useDispatch()
    const [birthdate, setBirthdate] = useState('')
    const [gender, setGender] = useState('')
    const genders = [{value: 'm', label: t('Male')}, {value: 'f', label: t('Female')}, {value: 'x', label: t('not-mentioned')}]

    useEffect(() => {
        //This async function was created because useEffect cant be an async function
        const fetchToken = async () => {
            const URLCode = legacyCode || params.code
            /*Verify if a code has been given by URL (QR code), if the code exist in the DB, and if it has a user
              if true then login directly*/
            if (URLCode && await verifyCode(URLCode) && await verifyCodeRegister(URLCode))
                dispatch(login(await getToken({code: URLCode})))
        }
        fetchToken()
        // dispatch(setAlert("Sorry, we are down for scheduled maintenance right now. All languages will be available soon."))
        document.body?.classList.remove(darkTheme ? 'bg-dark' : 'bg-white');
        document.body?.classList.remove(darkTheme ? 'bg-landing' : 'bg-landing-light');
        document.body?.classList.add(darkTheme ? 'bg-login' : 'bg-login-light');
    })

    useEffect(() => {
        document.body?.classList.remove(darkTheme ? 'bg-login-light': 'bg-login');
        document.body?.classList.add(darkTheme ? 'bg-login': 'bg-login-light');
    }, [darkTheme])

    const handleSubmit = async (event) => {
        event.preventDefault()
        var token
        switch(step) {
            case 'code':
                //verify if code exist , if true continue, else error
                if (await verifyCode(code)) {
                    //verify if code is assigned to a user, if true login, else ask for email
                    if (await verifyCodeRegister(code))
                        dispatch(login(await getToken({code})))
                    else {
                        setError(null)
                        setStep('email')
                    }
                }
                else
                    setError('invalid-code')
                break
            case 'email':
                //verify if the email is registered, if true continue to password, else error
                if (await verifyEmail(email)) {
                    setError(null)
                    setStep('password')
                } else {
                    setError('invalid-email')
                }
                break
            case 'password':
                //try to get token, if no token send error
                token = await getToken({ email, password })
                if(token.access) {
                    setError(null)
                    //if user is trying to add a code ask for confirmation
                    if(code) setStep('confirm')
                    else dispatch(login(await getToken({ email, password })))
                } else
                    setError('invalid-password')
                break
            case 'sign':
                // verify all data has been provided
                if (email && password && firstname && lastname && phone && work && city && country && birthdate && gender) {
                    if (password.length > 7) {
                        //verify if email has been registered before, if true login, else ask for confirmation
                        if(await verifyEmail(email)) {
                            token = await getToken({ email, password })
                            if(token.access) {
                                //if user is trying to add a code ask for confirmation
                                if(code) {
                                    setError(null)
                                    setStep('confirm')
                                }
                                else dispatch(login(await getToken({ email, password })))
                            } else
                                setError('valid-email-invalid-password')
                        } else {
                            setError(null)
                            setStep('confirm')
                        }
                    } else {
                        setError('invalid-password-lenght')
                    }
                } else
                    setError('invalid-info')
                break
            case 'confirm':
                //verify if we have all data, if true then we come from "create account form" 
                //else we come from trying to add a code to an existing account
                if (email && password && firstname && lastname && phone && work && city && country) {
                    //verify if email has been registered before, if false create account
                    token = await getToken({ email, password })
                    if(!token?.access) {
                        await createAccount({firstname, lastname, country, phone, city, work, email, password, birthdate, gender, preferred_language: language.id})
                        //we get the token of the new created account in case of needing to add a code
                        token = await getToken({ email, password })
                    }
                    //if user is trying to add a code add it
                    if (code) await addCode(token, code)
                    //login
                    dispatch(login(await getToken({ email, password })))
                }
                else {
                    token = await getToken({ email, password })
                    await addCode(token, code)
                    dispatch(login(await getToken({ email, password })))
                }
                break
            default:
                console.log("Not managed " + step)
        }
    }

    return (
        <div className="d-flex flex-column align-items-center justify-content-center py-5 pb-5 my-5">
            <Link className="col-6" to='/'>
                <img id="GLSNowLogo" className="img-fluid" src={ logoWhite } alt="pageLogo" style={{maxWidth: '220px', margin: 'auto', display: 'block'}} />
            </Link>
            <h3 className="text-white mt-5 FontSecondary">{t("welcome")}</h3>
            <form className="col-11 col-sm-7 col-md-6 col-lg-3 mt-6" onSubmit={handleSubmit}>
                <center>
                    {/* The next part is the message indicating you are activating a code */}
                    {step !== 'code' && step !== 'confirm' && code && 
                        <p className="alert bg-transparent text-primary mt-3 mb-4">
                            {t("code-activation-notification")} <span className="FontBold"> {code} </span>
                        </p>
                    }
                    {/* The next part is a small label indicating the step the user is at */}
                    <p className={`text-white mb-3 mt-3`}>
                        {t(`access-label-${step}`)}
                    </p>
                    {/* The next line is the error message */}
                    {error && <p className={`${darkTheme && 'text-white'} alert bg-danger mb-3`}>{t(error)}</p>}
                    {step === 'code' &&
                        <input type="text" className={`${darkTheme && 'text-dark'} form-control bg-white mb-4`} id="code"
                            placeholder={t("code")} value={code} onChange={(e) => setCode(e.target.value)}
                        />
                    }
                    {step === 'email' && 
                        <input type="email" className={`${darkTheme && 'text-dark'} form-control bg-white mb-4`} id="email"
                            placeholder={t("mail")} value={email} onChange={(e) => setEmail(e.target.value)}
                        />
                    }
                    {step === 'password' && 
                        <input type="password" className={`${darkTheme && 'text-dark'} form-control bg-white mb-4`} id="password"
                            placeholder={t("password")} value={password} onChange={(e) => setPassword(e.target.value)}
                        />
                    }
                    {step === 'sign' &&
                        <>
                            {/* This is the form for creating an account */}
                            <input type="email" className={`${darkTheme && 'text-dark'} form-control bg-white mb-4`}
                                placeholder={t("mail")} value={email} onChange={(e) => setEmail(e.target.value)}
                            />
                            <input type="text" className={`${darkTheme && 'text-dark'} form-control bg-white mb-4`}
                                placeholder={t("first-name")} value={firstname} onChange={(e) => setFirstName(e.target.value)}
                            />
                            <input type="text" className={`${darkTheme && 'text-dark'} form-control bg-white mb-4`}
                                placeholder={t("last-name")} value={lastname} onChange={(e) => setLastName(e.target.value)}
                            />
                            <div className="input-group mb-3">
                                <span className={`${darkTheme && 'text-white bg-dark'} input-group-text`} id="basic-addon1">{t('birthdate')}</span>
                                <input type="date" className={`${darkTheme && 'text-dark'} form-control bg-white`} style={{colorScheme: 'dark'}}
                                value={birthdate} onChange={(e) => setBirthdate(e.target.value)}/>
                            </div>
                            <Selector className="text-white" placeholder={t('gender')} options={genders} onChange={(value) => setGender(value)} />
                            <input type="text" className={`${darkTheme && 'text-dark'} form-control bg-white mb-4`}
                                placeholder={t("phone")} value={phone} onChange={(e) => setPhone(e.target.value)}
                            />
                            <Selector placeholder={t('country')} options={countries} onChange={(value) => setCountry(value)} />
                            <input type="text" className={`${darkTheme && 'text-dark'} form-control bg-white mb-4`}
                                placeholder={t("city")} value={city} onChange={(e) => setCity(e.target.value)}
                            />
                            <input type="text" className={`${darkTheme && 'text-dark'} form-control bg-white mb-4`}
                                placeholder={t("occupation")} value={work} onChange={(e) => setWork(e.target.value)}
                            />
                            <input type="text" className={`${darkTheme && 'text-dark'} form-control bg-white mb-4`} id="password"
                                placeholder={t("password")} value={password} onChange={(e) => setPassword(e.target.value)}
                            />
                            <Button type="button" className="mx-1 my-2" onClick={() => {setStep('email'); setError(null)}}>
                                {t("back")}
                            </Button>
                        </>
                    }
                    {step === 'confirm' &&
                        <>
                            {/* The next text adjusts when adding a code or just creating an account */}
                            {code 
                                ? <p className="alert bg-white mt-3 mb-3">
                                    {t('confirmation-label-1')} <span className="FontBold"> {code} </span><br/>{t('confirmation-label-2')} <span className="FontBold"> {email} </span> ?
                                </p>
                                : <h5 className="alert bg-white mt-3 mb-3">{t('create-account-confirmation-label')} <span className="FontBold"> {email} </span></h5>
                            }
                            {/* The next button is used to cancel the create account or add code process */}
                            <Link to='/' className="me-2">
                                <Button>{t("cancel")}</Button>
                            </Link>
                        </>
                    }
                    {/* The next button is used in all the login steps */}
                    <Button className="mx-1 my-2">
                        {step === 'sign' ? t("sign-up") : step === 'confirm' ? t("confirm") : t("login")}
                    </Button>
                    {step === 'password' && 
                            <a href="https://api.conectafy.com/accounts/password/reset/" target="_blank">
                                <Button type='button' className="my-2" onClick={ () => window.open('https://api.conectafy.com/accounts/password/reset/', '_blank')}>
                                    {t("forgot-password")}
                                </Button>
                            </a>
                            
                    }
                    {step === 'email' &&
                        <>
                            {/* This is the create account option when entering an email */}
                            <h5 className={`${darkTheme && 'text-white'} mt-3 mb-3`}>{t('create-account-label')}</h5>
                            <Button className="me-2" type="button"
                                onClick={() => {setStep('sign'); setError(null)}}
                            >
                                {t("create-account")}
                            </Button>
                        </>
                    }
                </center>
            </form>
        </div>
    )
}

export default withNamespaces()(Login);