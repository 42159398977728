import { withNamespaces } from 'react-i18next';
import { BsChevronDown } from 'react-icons/bs'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const LanguagesModalButton = ({ t }) => {
    const darkTheme = useSelector(state => state.darkTheme)
    const token = window.localStorage.getItem('GLSNToken')
    const path = useLocation()
    return (
        <button type="button" className="btn btn-transparent" data-bs-toggle="modal" data-bs-target="#languageModal">
            <span className={`${darkTheme ? 'text-dark FontBold' : 'text-light FontBold '}`} 
            style={{ textShadowOffset: {width:2, height: 2}, textShadowRadius: 5, textShadowColor: 'black', fontSize: '12px' }}>
                {t('select-language')} <BsChevronDown className={`text-secondary fa-solid`} size="15px" />
            </span>
        </button>
    )
}

export default withNamespaces()(LanguagesModalButton);