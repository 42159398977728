import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";

import eng from './languages/eng.json';
import spa from './languages/spa.json';
import ces from './languages/ces.json';
import dan from './languages/dan.json';
import est from './languages/est.json';
import fra from './languages/fra.json';
import hrv from './languages/hrv.json';
import isl from './languages/isl.json';
import nld from './languages/nld.json';
import pol from './languages/pol.json';
import swa from './languages/swa.json';
import vie from './languages/vie.json';
import amh from './languages/amh.json';
import ara from './languages/ara.json';
import ben from './languages/ben.json';
import bul from './languages/bul.json';
import cat from './languages/cat.json';
import ell from './languages/ell.json';
import fao from './languages/fao.json';
import fin from './languages/fin.json';
import hun from './languages/hun.json';
import ind from './languages/ind.json';
import lav from './languages/lav.json';
import lit from './languages/lit.json';
import may from './languages/may.json';
import mal from './languages/mal.json';
import mkd from './languages/mkd.json';
import mon from './languages/mon.json';
import mya from './languages/mya.json';
import nor from './languages/nor.json';
import por from './languages/por.json';
import ptp from './languages/ptp.json';
import ron from './languages/ron.json';
import rus from './languages/rus.json';
import sch from './languages/sch.json';
import slk from './languages/slk.json';
import sqi from './languages/sqi.json';
import srp from './languages/srp.json';
import swe from './languages/swe.json';
import tch from './languages/tch.json';
import ukr from './languages/ukr.json';

// the translations
const resources = {
  eng: {
    translation: eng
  },
  spa: {
    translation: spa
  },
  ces: {
    translation: ces
  },
  dan: {
    translation: dan
  },
  est: {
    translation: est
  },
  fra: {
    translation: fra
  },
  hrv: {
    translation: hrv
  },
  isl: {
    translation: isl
  },
  nld: {
    translation: nld
  },
  pol: {
    translation: pol
  },
  swa: {
    translation: swa
  },
  vie: {
    translation: vie
  },
  amh: {
    translation: amh
  },
  ara: {
    translation: ara
  },
  ben: {
    translation: ben
  },
  bul: {
    translation: bul
  },
  cat: {
    translation: cat
  },
  ell: {
    translation: ell
  },
  fao: {
    translation: fao
  },
  fin: {
    translation: fin
  },
  hun: {
    translation: hun
  },
  ind: {
    translation: ind
  },
  lav: {
    translation: lav
  },
  lit: {
    translation: lit
  },
  mal: {
    translation: mal
  },
  may: {
    translation: may
  },
  mkd: {
    translation: mkd
  },
  mon: {
    translation: mon
  },
  mya: {
    translation: mya
  },
  nor: {
    translation: nor
  },
  por: {
    translation: por
  },
  ptp: {
    translation: ptp
  },
  ron: {
    translation: ron
  },
  rus: {
    translation: rus
  },
  sch: {
    translation: sch
  },
  slk: {
    translation: slk
  },
  sqi: {
    translation: sqi
  },
  srp: {
    translation: srp
  },
  swe: {
    translation: swe
  },
  tch: {
    translation: tch
  },
  ukr: {
    translation: ukr
  }
};

i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "spa",

    keySeparator: false, // we do not use keys

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;