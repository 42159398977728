import { withNamespaces } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import bg from '../assets/img/footer-no-logo.png';
import bgLight from '../assets/img/footer-no-logo.png';
import logo from '../assets/img/logo-white.png';

const Footer = ({ t }) => {
    const darkTheme = useSelector(state => state.darkTheme)

    return (
        <div className='full-footer'>
        <footer className="footer row align-items-center justify-content-between col-12 pt-5 pb-2 ps-0 ps-sm-3 pe-0 pe-sm-0 m-0" >
            <div className='col-12 col-md-5 FontBold'>
                <img className='img-fluid mt-2 me-4 d-none d-lg-block pe-0 ms-5 pb-0' src={logo} alt='GLN' style={{ maxHeight: '11px', float: 'left'}}/>
            </div>
            <div className='d-flex justify-content-end align-items-center col-12 col-md-7 pe-lg-5 pe-sm-1 fst-italic mt-1'
            style={{backgroundImage: `url(${darkTheme ? bg : bgLight})`, backgroundSize: '100% 100%', minHeight: '40px'}}>
                <Link to='/' className={`FontBold text-light text-xs`}>{t('copyright')}</Link>
                <Link to='/' className={`ms-3 FontBold text-light text-xs`}>{t('terms-of-use')}</Link>
                <Link to='/' className={`ms-3 me-0 me-sm-5 FontBold text-light text-xs`}>{t('contact-us')}</Link>
            </div>
        </footer> 
        </div>
    )
}


export default withNamespaces()(Footer);