import { Link } from "react-router-dom";
import { withNamespaces } from 'react-i18next';
import logo from '../assets/img/logo-color.png'
import logoGreen from '../assets/img/logo-green.png'
import { Button } from "../Components";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const Landing = ({ t }) => {
    const darkTheme = useSelector(state => state.darkTheme)

    useEffect(() => {
        document.body?.classList.remove(darkTheme ? 'bg-dark' : 'bg-white');
        document.body?.classList.remove(darkTheme ? 'bg-login' : 'bg-login-light');
        document.body?.classList.add(darkTheme ? 'bg-landing': 'bg-landing-light');
    });

    useEffect(() => {
        /* var backgroundImages = [bgimg1, bgimg2, bgimg3];
        var backgroundImagesLight = [bgimg1Li, bgimg2Li, bgimg3Li];
            $(function () {
                var i = 0;
                $(".bg-landing").css("background-image", "url(" + backgroundImages[i] + ")");
                $(".bg-landing-light").css("background-image", "url(" + backgroundImagesLight[i] + ")");
                setInterval(function () {
                    i++;
                    if (i == backgroundImages.length) {
                        i = 0;
                    }
                    $(".bg-landing").css("background-image", "url(" + backgroundImages[i] + ")");;
                    $(".bg-landing-light").css("background-image", "url(" + backgroundImagesLight[i] + ")");;
                }, 8000);
            }); */
    });

    useEffect(() => {
        document.body?.classList.remove(darkTheme ? 'bg-landing-light': 'bg-landing');
        document.body?.classList.add(darkTheme ? 'bg-landing': 'bg-landing-light');
    }, [darkTheme])


    return (
        <div className="full-landing" style={{ maxWidth: '90%' }}>
            {/* Desktop Layout */}
            <div className="d-none d-lg-block ">
                <div className="row mt-2 mb-5">
                    <div className="d-flex mb-5 mt-2">
                        <div className="d-flex flex-column align-items-center d-lg-block pt-2 col-12 col-lg-6" >
                            <img className="ps-5 mt-5 mb-5" src={ darkTheme ? logoGreen : logo } style={{maxHeight: '120px', maxWidth: '600px'}} alt="landingLogo"/>
                            <h6 className={`${darkTheme && 'text-white'} ms-5 mt-4 FontSecondary`}>
                                <p className="mb-1 fw-lighter text-uppercase">{t("landing-header-1")}</p>
                                <b className="text-uppercase">{t("landing-header-2")}</b>
                            </h6>
                            <p className={`${darkTheme && 'text-white'} ms-5 mt-4 mb-5 w-75`}>
                                {t("landing-label-0")}
                                <br/>
                                <b className="FontBold">{t("landing-label-00")}</b>
                                <br/>
                                {t("landing-label-2")}
                                <span hidden className="">
                                    {t("landing-label-1")}
                                    <br/> 
                                </span>
                                
                            </p>
                            <div className="ms-5 mt-4 mb-1">
                                <Link to="/login/code" className="my-2 me-3" style={{minWidth: '200px'}}>
                                    <Button>{t("access-code-button")}</Button>
                                </Link>
                                <Link to="/login/email" className="my-2 ms-3" style={{minWidth: '200px'}}>
                                    <Button>{t("access-email-button")}</Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div hidden className="row mt-3 pt-3 float-end">
                    <ul className='list-group list-group-horizontal float-end'>
                        <Link to="/section1"><li className={`${darkTheme && 'text-white'} list-group-item bg-transparent text-decoration-underline text-sm FontBold`}><small>{t("link-section1")}</small></li></Link>
                        <Link to="/copyright"><li className={`${darkTheme && 'text-white'} list-group-item bg-transparent text-decoration-underline text-sm FontBold`}><small>{t("link-section2")}</small></li></Link>
                        <Link to="/terms-of-use"><li className={`${darkTheme && 'text-white'} list-group-item bg-transparent text-decoration-underline text-sm FontBold`}><small>{t("link-section3")}</small></li></Link>
                        <Link to="/contact-us"><li className={`${darkTheme && 'text-white'} list-group-item bg-transparent text-decoration-underline text-sm FontBold`}><small>{t("link-section4")}</small></li></Link>
                    </ul>
                </div>
            </div>
            {/* Tablet & Mobile Layout */}
            <div className="d-md-block d-lg-none">
                <div className="row my-2">
                    <div className="d-flex mt-5">
                        <div className="d-flex flex-column align-items-center d-lg-block col-12 col-lg-6 pt-1" >
                            <center>
                                <img className="ps-2 mb-3" src={ darkTheme ? logoGreen : logo } style={{maxHeight: '100px', maxWidth: '300px'}} alt="landingLogo"/>
                                <br/>
                                <h6 className={`${darkTheme && 'text-white'} ps-2 py-3 mt-4 FontSecondary mb-0 landing-header-mobile`} style={{backgroundColor: 'rgba(0,0,0,.30'}}>
                                    <p className="mb-3 fw-lighter text-uppercase">{t("landing-header-1")}</p>
                                    <b className="text-uppercase">{t("landing-header-2")}</b>
                                </h6>
                                <div className="ps-2 mt-4">
                                    <Link to="/login/code" className="m-2" style={{minWidth: '200px'}}>
                                        <Button>{t("access-code-button")}</Button>
                                    </Link>
                                    <div style={{ minHeight: '20px' }}/>
                                    <Link to="/login/email" className="m-2" style={{minWidth: '200px'}}>
                                        <Button>{t("access-email-button")}</Button>
                                    </Link>
                                </div>
                                <p className={`${darkTheme && 'text-white'} ms-2 mt-5  landing-subtitle-mobile`}>
                                    {t("landing-label-0")} <br></br><span className="FontBold">{t("landing-label-00")}</span>                                    <br/>
                                    <span hidden className="FontBold">
                                        {t("landing-label-1")}
                                        <br/>
                                        {t("landing-label-2")}
                                    </span>
                                </p>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withNamespaces()(Landing);