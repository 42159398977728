import { NavLink, useLocation } from "react-router-dom"
import logo from '../../assets/img/logo-color.png'
import { BsList, BsPersonFill } from 'react-icons/bs'
import { FaRunning } from 'react-icons/fa'
import { RiFileCopy2Fill, RiPlayCircleFill } from "react-icons/ri"
import { IoIosColorPalette } from "react-icons/io"
import { FiLogIn } from "react-icons/fi"
import { useDispatch, useSelector } from "react-redux"
import { logout, toggleDarkTheme } from "../../Redux/actions"
import { LanguagesModalButton } from "../LanguagesModal"
import { withNamespaces } from 'react-i18next';
import './NavBar.css'

const NavBar = ({ t }) => {
    const token = window.localStorage.getItem('GLSNToken')
    const darkTheme = useSelector(state => state.darkTheme)
    const dispatch = useDispatch()
    const path = useLocation()

    const handleTheme = () => {
        dispatch(toggleDarkTheme(darkTheme))
    }

    const handleLogout = () => {
        dispatch(logout())
    }

    return (
        <nav className={(token || (path.pathname !== '/' && !path.pathname.includes('login')))
            ? `navbar navbar-expand header sticky-top ${darkTheme ? 'bg-white': 'bg-dark'}`
            : "navbar navbar-expand header bg-transparent"}>
            {(path.pathname !== '/' && !path.pathname.includes('login')) && <NavLink to='/' className="ms-3" style={{zIndex: 1}}>
                <img className='logo' src={ logo } alt='logo' />
            </NavLink>}
            {token && <>
                <ul className="navbar-nav position-absolute justify-content-center w-100 ms-auto me-auto d-none d-md-flex">
                    <li className="nav-item pe-lg-3 pe-sm-0">
                        <NavLink to='/videos/sessions'><span className={`mainLink ${darkTheme ? 'text-dark' : 'text-light'}`}> {t("sessions")} </span></NavLink>
                    </li>
                    <li className="nav-item pe-lg-3 pe-sm-0">
                        <NavLink to='/videos/grander_vision'><span className={`${darkTheme ? 'text-dark' : 'text-light'}`}> {t("grander-vision")} </span></NavLink>
                    </li>
                    <li className="nav-item pe-lg-3 pe-sm-0pe-sm-0">
                        <NavLink to='/videos/bonus'><span className={`${darkTheme ? 'text-dark' : 'text-light'}`}> {t("bonus-resources")} </span></NavLink>
                    </li>
                    <li className="nav-item pe-3 d-none d-lg-flex">
                        <NavLink to='/discussion_guides'><span className={`${darkTheme ? 'text-dark' : 'text-light'}`}> {t("discussion-guides")} </span></NavLink>
                    </li>
                </ul>
            </>}
            <ul className="navbar-nav align-items-center me-3 ms-auto" style={{zIndex: 1051}}>
                <li className="nav-item me-3 ms-auto">
                    {(!token || path.pathname === '/profile') && <LanguagesModalButton />}
                </li>

                {!token && <>
                    <NavLink to='/login/code' className="me-lg-4 me-sm-1 text-uppercase FontBold" style={{zIndex: 1}}>
                        <span className={`${darkTheme ? 'text-light' : 'text-dark'}`} style={{ fontSize: '14px' , marginRight: '20px'}}>
                        {t("login")} </span><FiLogIn hidden className={`text-secondary`}  size="20px" />
                    </NavLink>
                </>}

                <div className="dropdown">
                    <a href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                        <BsList className={`text-secondary`} size="25px"/>
                    </a>

                    <ul className={`dropdown-menu ${darkTheme ? 'bg-white' : 'bg-dark'}`} aria-labelledby="dropdownMenuLink" style={{left: 'unset', right: 0}}>
                        {token &&
                            <li className="ps-1 pe-1">
                                <NavLink to='/profile' className={`dropdown-item rounded-pill highlight text-end ${darkTheme ? 'text-dark' : ' text-white'}`}>
                                    {t("my-profile")} <BsPersonFill />
                                </NavLink>
                            </li>
                        }
                        <li className="ps-1 pe-1">
                            <div className={`dropdown-item rounded-pill highlight text-end ${darkTheme ? 'text-dark' : ' text-white'}`} onClick={() => handleTheme()}>
                                {darkTheme ? t("light-theme") : t("dark-theme")} <IoIosColorPalette />
                            </div>
                        </li>
                        {token &&
                            <li  className="ps-1 pe-1" onClick={handleLogout}>
                                <NavLink to='/' className={`dropdown-item rounded-pill highlight text-end ${darkTheme ? 'text-dark' : ' text-white'}`}>
                                    {t("logout")} <FaRunning />
                                </NavLink>
                            </li>
                        }
                    </ul>
                </div>
            </ul>
        </nav>
    )
}

export default withNamespaces()(NavBar);