import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { BsFillVolumeUpFill } from "react-icons/bs";
import { FaPlay } from "react-icons/fa";
import { RiFileCopy2Fill } from "react-icons/ri"
import { BsPipFill } from "react-icons/bs"
import { FiArrowDown } from "react-icons/fi";
import WebViewer from '@pdftron/pdfjs-express-viewer';
import { withNamespaces } from 'react-i18next';
import { Button, Notes } from "../Components";
import pages_num_speakers from '../assets/pages_num_speakers.js';

const Player = ({ t }) => {
    const params = useParams()
    const language = useSelector(state => state.language.selected)
    const stateVideo = useSelector(state => state.videos.filter(video => video.id === parseInt(params.videoId)));
    const video = stateVideo[0]
    const defaultTitle = video?.titles.filter(title => title.lang_code === 'eng')
    const title = video?.titles.filter(title => title.lang_code === language.key)
    const defaultDescription = video?.descriptions.filter(description => description.lang_code === 'eng')
    const description = video?.descriptions.filter(description => description.lang_code === language.key)
    const stateGuides = useSelector(state => state.guides)
    const yearGuides = stateGuides.filter(guide => guide.description === video?.year)
    const languageGuide = yearGuides.filter(guide => guide.language === language.name)
    const englishGuide = yearGuides.filter(guide => guide.language === 'English')
    const guide = languageGuide[0] || englishGuide[0]
    const guide_url = guide?.file_uri

    const viewer = useRef(null);

    const darkTheme = useSelector(state => state.darkTheme)

    useEffect(() => {
        if(video) {
            window['setAudioPlayer'](video, language)
            window['setVideoPlayer'](video, language)
        }
    }, [video])

    useEffect(() => {
        window['changeLanguage'](video, language)
    }, [language])

    // Creating the PDF viewer
    useEffect(() => {
        WebViewer({
            path: '/webviewer/lib',
            initialDoc: video?.single_file_available ? video?.single_file_uri : guide_url,
            licenseKey: 'pvo0S790JSGWD0fLh57x',
        }, viewer.current,
        ).then((instance) => {
            // now you can access APIs through the WebViewer instance
            const { Core } = instance;
            instance.UI.setTheme('dark');
            // adding an event listener for when a document is loaded
            Core.documentViewer.addEventListener('documentLoaded', () => {
              if (video && !video?.single_file_available) {
                const page_num_speaker = pages_num_speakers.filter(i => i.name === video.speaker);
                const page_num = page_num_speaker[0]["page"];
                instance.docViewer.setCurrentPage(page_num);
              }
            });
        });
    }, [video, guide_url]);

    return (
        <>
            {video &&
                <>
                    <div className="tab-content mt-0">
                        <div className="tab-pane active mb-4" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <div id='player' />
                        </div>
                        <div className="tab-pane mb-4" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            <div className="d-flex justify-content-center align-items-center h-100">
                                <div id='audioplayer' />
                            </div>
                        </div>
                    </div>
                    <ul className="nav d-flex d-md-none mb-4 ms-5 me-5" id="myTab" role="tablist">
                        <li className="col-4" role="presentation">
                            <button className={`btn text-uppercase fw-bold col-12 active ${darkTheme ? 'btn-outline-light' : 'btn-outline-secondary'}`} 
                            id="home-tab" data-bs-toggle="tab" data-bs-target="#home" role="tab" aria-controls="home" aria-selected="true"
                            style={{borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                            onClick={() => window['togglePlayer']('video')}>
                                <FaPlay /> {t("video")}
                            </button>
                        </li>
                        <li className="col-4" role="presentation">
                            <button className={`btn text-uppercase fw-bold col-12 ${darkTheme ? 'btn-outline-light' : 'btn-outline-secondary'}`}
                            id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" role="tab" aria-controls="profile" aria-selected="false"
                            style={{}}
                            onClick={() => window['togglePlayer']('audio')}>
                                <BsFillVolumeUpFill /> {t("audio")}
                            </button>
                        </li>
                        <li className="col-4" role="presentation">
                            <button className={`btn text-uppercase fw-bold col-12 ${darkTheme ? 'btn-outline-light' : 'btn-outline-secondary'}`}
                            id="togglePipButtonMobile" data-bs-toggle="tab" role="tab"
                            style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: '10px', borderBottomRightRadius: '10px'}}
                            >
                                <BsPipFill /> &nbsp;&nbsp;&nbsp;PiP&nbsp;&nbsp;&nbsp;
                            </button>
                        </li>
                    </ul>
                    <div id="videoInfoCard" className="row justify-content-between ps-5 pe-5 pe-md-5 ms-0 me-0">
                        <div className="card col-12 col-md-6 p-3">
                            <h2 className="mb-0 FontBold">{video.speaker}</h2>
                            <h4 className="mt-1">{title[0]?.title || defaultTitle[0].title}</h4>
                            <p className="mt-3">{description[0]?.description || defaultDescription[0]?.description}</p>
                        </div>
                        <div className="col-12 col-md-6 col-lg-5 p-0 ps-md-3">
                            <ul className="nav d-none d-md-flex" id="myTab" role="tablist">
                                <li className="col-4" role="presentation">
                                    <button className={`btn text-uppercase fw-bold col-12 active ${darkTheme ? 'btn-outline-light' : 'btn-outline-secondary'}`}
                                    id="home-tab" data-bs-toggle="tab" data-bs-target="#home" role="tab" aria-controls="home" aria-selected="true"
                                    style={{borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', borderTopRightRadius: 0, borderBottomRightRadius: 0}}
                                    onClick={() => window['togglePlayer']('video')}>
                                        <FaPlay /> {t("video")}
                                    </button>
                                </li>
                                <li className="col-4" role="presentation">
                                    <button className={`btn text-uppercase fw-bold col-12 ${darkTheme ? 'btn-outline-light' : 'btn-outline-secondary'}`}
                                    id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" role="tab" aria-controls="profile" aria-selected="false"
                                    style={{ }}
                                    onClick={() => window['togglePlayer']('audio')}>
                                        <BsFillVolumeUpFill /> {t("audio")}
                                    </button>
                                </li>
                                <li className="col-4" role="presentation">
                                    <button className={`btn text-uppercase fw-bold col-12 ${darkTheme ? 'btn-outline-light' : 'btn-outline-secondary'}`}
                                    id="togglePipButtonDesktop"
                                    style={{borderTopLeftRadius: 0, borderBottomLeftRadius: 0, borderTopRightRadius: '10px', borderBottomRightRadius: '10px'}}
                                    >
                                        <BsPipFill /> PiP
                                    </button>
                                </li>
                            </ul>
                            <div id="discussionGuideCard" className="card p-4 mt-3 ms-0" style={{minHeight: '140px'}}>
                                <h4 className="text-center mb-3">{guide?.title}</h4>
                                <div className="d-flex align-items-center flex-column flex-md-row">
                                    <div className="ms-4 d-flex align-items-center">
                                        <RiFileCopy2Fill size={45}/>
                                        <h5 className="ms-1 me-1 mb-0">{guide?.description}</h5>
                                    </div>
                                    <a target='_blank' href={video?.single_file_available ? video?.single_file_uri : guide?.file_uri} rel="noreferrer"
                                    className="ms-0 ms-md-auto mt-2 mt-md-0">
                                        <Button secondary><FiArrowDown/> {guide?.download_string}</Button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10 mx-auto">
                        <ul className="nav nav-tabs ms-5 me-5 mt-3 border-0" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link text-secondary active" id="guides-tab" data-bs-toggle="tab" data-bs-target="#guides" type="button" role="tab" aria-controls="guides" aria-selected="true">
                                    {t('discussion-guides')}
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link text-secondary" id="notes-tab" data-bs-toggle="tab" data-bs-target="#notes" type="button" role="tab" aria-controls="notes" aria-selected="false">
                                    {t('notes')}
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane active" id="guides" role="tabpanel" aria-labelledby="guides-tab">
                                <div className="webviewer ms-5 me-5 bg-primary" ref={viewer}
                                style={{ height: '720px', borderBottomLeftRadius: '15px', borderTopRightRadius: '15px', borderBottomRightRadius: '15px'}} />
                            </div>
                            <div className="tab-pane" id="notes" role="tabpanel" aria-labelledby="notes-tab">
                                <Notes />
                            </div>
                        </div>
                    </div>
                    
                </>
            }
        </>
    )
}

export default withNamespaces()(Player);