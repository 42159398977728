import { withNamespaces } from 'react-i18next';
import { useEffect } from "react";
import { useSelector } from 'react-redux';

const Feedback = ({ t }) => {
    const darkTheme = useSelector(state => state.darkTheme)

    useEffect(() => {
        document.body?.classList.remove(darkTheme ? 'bg-login' : 'bg-login-light');
        document.body?.classList.remove(darkTheme ? 'bg-landing' : 'bg-landing-light');
        document.body?.classList.add(darkTheme ? 'bg-dark' : 'bg-white');
    });

    useEffect(() => {
        document.body?.classList.remove(darkTheme ? 'bg-white': 'bg-dark');
        document.body?.classList.add(darkTheme ? 'bg-dark': 'bg-white');
    }, [darkTheme])

    return (
        <div className='container' style={{ minHeight: '100vh' }}>
            <div className="card overflow-visible px-1 pt-4 p-md-5 m-4 m-md-5">
            <iframe height="1372" title="Embedded Wufoo Form" allowtransparency="true" frameborder="0" scrolling="no" sandbox="allow-top-navigation allow-scripts allow-popups allow-forms allow-same-origin" src="https://glsnow.wufoo.com/embed/z1kb8d1x0gcsr5h/"> <a href="https://glsnow.wufoo.com/forms/z1kb8d1x0gcsr5h/">Fill out my Wufoo form!</a> </iframe>
            </div>
        </div>
    )
}

export default withNamespaces()(Feedback);